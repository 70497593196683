<template>
  <!-- 退改签规则弹窗，确认过一次记录到localstorage中 下一次就不展示了 -->
  <van-dialog v-model="show" title="改退规则" :close-on-click-overlay="true" :before-close="beforeClose">
    <p class="list-text-big">改签规则</p>
    <p class="list-text">（1）开车前48小时（不含）以上，可改签预售期内的其他列车</p>
    <p class="list-text">（2）开车前48小时以内，可改签开车前的其他列车，也可改签开车后至票面日期当日24:00之间的其他列车，不办理票面日期次日及以后的改签</p>
    <p class="list-text">（3）开车之后，旅客仍可改签当日其他列车，但只能在票面发站办理改签，且开车后改签的车票不能退票</p>
    <p class="list-text">（4）已经办理“变更到站”的车票，不再办理改签</p>
    <p class="list-text">（5）开车前48小时～8天期间内，改签至距开车15天以上的其他列车，又在距开车8天前退票的，仍核收5%的退票费</p>
    <div class="gap"></div>
    <p class="list-text-big">退票规则</p>
    <p class="list-text">（1）未换取纸质车票，可不晚于车票发站开车前在网上办理网络退票业务，也可于开车前到车站安装有POS机的窗口办理</p>
    <p class="list-text">（2）已换取纸质车票，开车前到车站安装有POS机的窗口办理</p>
    <p class="list-text">（3）在票面指定的开车时间前到车站办理，退还全部票价，核收退票费</p>
    <p class="list-text">（4）特殊情况经购票地车站或票面乘车站站长同意的，可在开车后2小时内办理，改签后的车票不予办理</p>
  </van-dialog>
</template>
<script>
export default {
  data() {
    return {
      show: false // 开关
    };
  },
  mounted() {
    if (!localStorage.getItem("showTrainRules")) this.show = true;
  },
  methods: {
    beforeClose(action, done) {
      localStorage.setItem("showTrainRules", true);
      done();
    }
  }
};
</script>

<style lang="less" scoped>
p {
  margin: 0;
  padding: 0 1rem;
}
</style>